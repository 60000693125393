import React, { Suspense } from 'react';
import { BrowserRouter, Routes as ListRoute, Navigate, Route } from 'react-router-dom';
import './App.css';
import path from './constants/path';
import './translate/i18n';

import { Skeleton } from 'antd';
import { privateRoutes, publicRoute } from './routes/Routes';

const App = () => {
    //   const publicRoute = publicRoute.map((route, index) => {
    //     const Page = route.component;

    //     return (
    //         <Route
    //             key={index}
    //             path={route.path}
    //             element={
    //                     <Page />
    //             }
    //         />
    //     );
    // });

    return (
        <div className="App">
            {/* <Suspense fallback={<div>Loading...</div>}> */}
            <Suspense fallback={<Skeleton></Skeleton>}>
                <BrowserRouter>
                    <ListRoute>
                        {publicRoute.map((route) => {
                            const Page = route.component;
                            return <Route key={route.path} element={<Page />} path={route.path} exact />;
                        })}
                        {privateRoutes.map((it) => {
                            const Page = it.component;

                            return (
                                <Route
                                    key={it.path}
                                    exact
                                    path={it.path}
                                    element={
                                        <RequireAuth>
                                            {/*{it.roles?.map(({role})=>(*/}
                                            {/*   role === account?.role ?*/}
                                            {/*	   it.component:''*/}
                                            {/*))*/}
                                            {/*}*/}
                                            <Page />
                                        </RequireAuth>
                                    }
                                />
                            );
                        })}
                    </ListRoute>
                </BrowserRouter>
            </Suspense>
        </div>
        // <div className="App">
        // <Suspense fallback={<div>Loading...</div>}>
        //     <BrowserRouter>
        //         <div className="App">
        //             <Routes>
        //                 {/* <Route element={<ProtectedRoutes />}> */}
        //                 {/* </Route>    */}
        //                 {publicRoute}
        //             </Routes>
        //         </div>
        //     </BrowserRouter>
        // </Suspense>
    );
};

function RequireAuth({ children }) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.email === undefined) {
        return <Navigate to={path.HOME} />;
    }
    return children;
}

export default App;
