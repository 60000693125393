import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ServiceApi } from "../../api/ServiceApi";


export const getServices= createAsyncThunk(
  "service/getService",
  async ()=>{
      const listService = await ServiceApi.getService();
      return listService
  }
  
)

export const getDetailService = createAsyncThunk(
    "service/getDetailService",
    async (id)=>{
        const getDetail= await ServiceApi.getDetailService(id);
        return getDetail
    }
    
)

export const requestService = createAsyncThunk(
    "service/request",
    async (data)=>{
        const requesSupport= await ServiceApi.requestService(data);
        return requesSupport  
    }
)

const serviceSlice = createSlice({
    name :"service",
    initialState: {
        getService :[],  
        detailService:{},
        loadingService:false,
    },
    reducers:{

    },
    extraReducers:{
        [getServices.pending]: (state) => {
            state.loadingService = true;
          },
          [getServices.rejected]: (state) => {
            state.loadingService = false;
          },
          [getServices.fulfilled]: (state, action) => {
            state.loadingService = false;
            state.getService = action.payload.data;
          },
          [getDetailService.pending]: (state) => {
            state.loadingService = true;
          },
          [getDetailService.rejected]: (state) => {
            state.loadingService = false;
          },
          [getDetailService.fulfilled]: (state, action) => {
            state.loadingService = false;
            state.detailService = action.payload.data;
          },
    }

})

const { reducer: serviceReducer } = serviceSlice;
export default serviceReducer;