import axiosClient from './AxiosClient';
export const ServiceApi = {
    getService() {
        const url = `services/search?type=SERVICE`;
        return axiosClient.get(url);
    },
    getDetailService(id) {
        const url = `services/${id}`;
        return axiosClient.get(url);
    },
    requestService(data) {
        const url = `support-request`;
        return axiosClient.post(url, data);
    },
};
