import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import HomeApi from '../../api/HomeApi';

export const getBanner = createAsyncThunk('home/getBanner', async () => {
    const listImageBanner = await HomeApi.getBanner();
    return listImageBanner;
});

export const getPartner = createAsyncThunk('home/partner', async () => {
    const res = await HomeApi.getPartner();
    return res;
});
export const searchByKey = createAsyncThunk('home/searchByKeyWord', async (keyword) => {
    const res = await HomeApi.searchByKeyword(keyword);
    return res;
});
export const getNews = createAsyncThunk('home/news', async () => {
    const res = await HomeApi.getNews();

    return res;
});

export const registerNewsHome = createAsyncThunk('home/registerNews', async (data) => {
    const res = await HomeApi.registerNews(data);
    return res;
});

export const getDetailNews = createAsyncThunk('home/detailNews', async (id) => {
    const res = await HomeApi.getDetailNews(id);
    return res;
});

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        listPartner: [],
        listBanner: [],
        loadingHome: false,
        dataSearch: [],
        listNews: [],
        // listRegisterNew: [],
        isRegisterNews: false,
        detailNews: {},
    },
    reducers: {},
    extraReducers: {
        [getBanner.pending]: (state) => {
            state.loadingHome = true;
        },
        [getBanner.rejected]: (state) => {
            state.loadingHome = false;
        },
        [getBanner.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.listBanner = action.payload.data;
        },
        [getPartner.pending]: (state) => {
            state.loadingHome = true;
        },
        [getPartner.rejected]: (state) => {
            state.loadingHome = false;
        },
        [getPartner.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.listPartner = action.payload.data;
        },
        [searchByKey.pending]: (state) => {
            state.loadingHome = true;
        },
        [searchByKey.rejected]: (state) => {
            state.loadingHome = false;
        },
        [searchByKey.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.dataSearch = action.payload.data;
        },
        [getNews.pending]: (state) => {
            state.loadingHome = true;
        },
        [getNews.rejected]: (state) => {
            state.loadingHome = false;
        },
        [getNews.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.listNews = action.payload.data;
        },
        [registerNewsHome.pending]: (state) => {
            state.loadingHome = true;
        },
        [registerNewsHome.rejected]: (state) => {
            state.loadingHome = false;
        },
        [registerNewsHome.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.isRegisterNews = true;
        },
        [getDetailNews.pending]: (state) => {
            state.loadingHome = true;
        },
        [getDetailNews.rejected]: (state) => {
            state.loadingHome = false;
        },
        [getDetailNews.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.detailNews = action.payload.data;
        },
    },
});

const { reducer: homeReducer } = homeSlice;
export default homeReducer;
