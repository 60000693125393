import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../api/Auth/AuthApi';

export const userLogin = createAsyncThunk('account/login', async (data) => {
    const res = await authApi.login(data);
    return res;
});
const usersSlice = createSlice({
    name: 'users',
    initialState: {
        loading: false,
        users: {},
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        }
    },
    extraReducers: {

    }

})

const { reducer: usersReducer } = usersSlice;
export default usersReducer;

