import axiosClient from './AxiosClient';
export const ProductApi = {
    getProductById(cateId) {
        const url = `products/search?categoryId=${cateId}`;
        return axiosClient.get(url);
    },
    getDetail(id) {
        const url = `products/${id}`;
        return axiosClient.get(url);
    },
    getRelated(id) {
        const url = `products/related?categoryId=${id}`;
        return axiosClient.get(url);
    },
    getProductPageHome() {
        const url = `products/all-paging/?type=PRODUCT`;
        return axiosClient.get(url);
    },
    getFieldProduct() {
        const url = `categories/products`;
        return axiosClient.get(url);
    },
    getListProduct() {
        const url = `/categories/products`;
        return axiosClient.get(url);
    },
    getListCategoryProduct(cateId) {
        const url = `products/all-paging?categoryId=${cateId}&type=PRODUCT`;
        return axiosClient.get(url);
    },
};
