import { configureStore } from '@reduxjs/toolkit';
import usersReducer from '../reducers/userSlice';
import homeReducer from '../reducers/homeSlice';
import productReducer from '../reducers/productSlice';
import aboutUsReducer from '../reducers/aboutUsSlice';
import applicationReducer from '../reducers/applicationSlice';
import serviceReducer from'../reducers/serviceSlice';
import supportReducer from '../reducers/supportSlice';
import contactReducer from '../reducers/contactSlice';

const store = configureStore({
    reducer: {
        usersReducer,
        homeReducer,
        aboutUsReducer,
        productReducer,
        applicationReducer,
        serviceReducer,
        supportReducer,
        contactReducer,

    },
});

export default store;
