import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aboutUsApi } from '../../api/AboutUsApi';
export const getCeoMessages = createAsyncThunk('aboutUs/getCeoMessage', async () => {
    const res = await aboutUsApi.getCeoMessages();
    return res;
});
export const getCompanyInfo = createAsyncThunk('aboutUs/getCompany', async () => {
    const res = await aboutUsApi.getCompanyInfo();
    return res;
});
export const getBussinessArea = createAsyncThunk('aboutUs/getBussiness', async () => {
    const res = await aboutUsApi.getBussines();
    return res;
});
export const getHistory = createAsyncThunk('aboutUs/getHistory', async () => {
    const res = await aboutUsApi.getHistory();
    return res;
});
export const getActivity = createAsyncThunk('aboutUs/getActivity', async () => {
    const res = await aboutUsApi.getOurActivity();
    return res;
});
//34
export const getActivityDetail = createAsyncThunk(`aboutUs/activitiesdetail`, async (id) => {
    const res = await aboutUsApi.getActivitiesDetail(id);
    return res;
});
export const getLocation = createAsyncThunk('aboutUs/getLoaction', async () => {
    const res = await aboutUsApi.getLocation();
    return res;
});
//35
export const getPartner = createAsyncThunk('aboutUs/getPartner', async () => {
    const res = await aboutUsApi.getPartner();
    return res;
});
export const getPromotionVideo = createAsyncThunk('aboutUs/getPromotion', async () => {
    const res = await aboutUsApi.getPromotion();
    return res;
});
export const getReport = createAsyncThunk('aboutUs/getReport', async (type) => {
    const res = await aboutUsApi.getReport(type);
    return res;
});
export const getNewProduct = createAsyncThunk('aboutUs/getNew', async () => {
    const res = await aboutUsApi.getNewProducts();
    return res;
});
export const getNewProductDetail = createAsyncThunk('aboutUs/getNewDetail', async (id) => {
    const res = await aboutUsApi.getNewProductDetail(id);
    return res;
});
export const getAllNewLetterByType = createAsyncThunk('aboutUs/getAllNewByType', async (type) => {
    const res = await aboutUsApi.getAllNewLetterByType(type);
    return res;
});
export const getSaleChanelByType = createAsyncThunk('aboutUs/getSaleByChanel', async (type) => {
    const res = await aboutUsApi.getSaleChanelByType(type);
    return res;
});

const aboutUsSlice = createSlice({
    name: 'aboutUs',
    initialState: {
        loading: false,
        listBussinessArea: [],
        listNewProducts: [],
        newProductDetail: {},
        listAllNewLetterByType: [],
        dataCeoMessages: {},
        dataCompanyInfo: {},
        dataHistory: {},
        listOurActivity: [],
        listReport: [],
        listLocation: [],
        listPartner: [],
        listPromotionVideo: [],
        listSaleChanel: [],
        activitiesDetail: {},
    },
    reducers: {},
    extraReducers: {
        [getBussinessArea.pending]: (state) => {
            state.loading = true;
        },
        [getBussinessArea.rejected]: (state) => {
            state.loading = false;
        },
        [getBussinessArea.fulfilled]: (state, action) => {
            state.loading = false;
            state.listBussinessArea = action.payload.data;
        },
        [getNewProduct.pending]: (state) => {
            state.loading = true;
        },
        [getNewProduct.rejected]: (state) => {
            state.loading = false;
        },
        [getNewProduct.fulfilled]: (state, action) => {
            state.loading = false;
            state.listNewProducts = action.payload.data;
        },
        [getNewProductDetail.pending]: (state) => {
            state.loading = true;
        },
        [getNewProductDetail.rejected]: (state) => {
            state.loading = false;
        },
        [getNewProductDetail.fulfilled]: (state, action) => {
            state.loading = false;
            state.newProductDetail = action.payload.data;
        },
        [getAllNewLetterByType.pending]: (state) => {
            state.loading = true;
        },
        [getAllNewLetterByType.rejected]: (state) => {
            state.loading = false;
        },
        [getAllNewLetterByType.fulfilled]: (state, action) => {
            state.loading = false;
            state.listAllNewLetterByType = action.payload.data;
        },
        [getCeoMessages.pending]: (state) => {
            state.loading = true;
        },
        [getCeoMessages.rejected]: (state) => {
            state.loading = false;
        },
        [getCeoMessages.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCeoMessages = action.payload.data;
        },
        [getCompanyInfo.pending]: (state) => {
            state.loading = true;
        },
        [getCompanyInfo.rejected]: (state) => {
            state.loading = false;
        },
        [getCompanyInfo.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCompanyInfo = action.payload.data;
        },
        [getHistory.pending]: (state) => {
            state.loading = true;
        },
        [getHistory.rejected]: (state) => {
            state.loading = false;
        },
        [getHistory.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataHistory = action.payload.data;
        },
        [getActivity.pending]: (state) => {
            state.loading = true;
        },
        [getActivity.rejected]: (state) => {
            state.loading = false;
        },
        [getActivity.fulfilled]: (state, action) => {
            state.loading = false;
            state.listOurActivity = action.payload.data;
        },
        [getActivityDetail.pending]: (state) => {
            state.loading = true;
        },
        [getActivityDetail.rejected]: (state) => {
            state.loading = false;
        },
        [getActivityDetail.fulfilled]: (state, action) => {
            state.loading = false;
            state.activitiesDetail = action.payload.data;
        },
        [getLocation.pending]: (state) => {
            state.loading = true;
        },
        [getLocation.rejected]: (state) => {
            state.loading = false;
        },
        [getLocation.fulfilled]: (state, action) => {
            state.loading = false;
            state.listLocation = action.payload.data;
        },
        [getPartner.pending]: (state) => {
            state.loading = true;
        },
        [getPartner.rejected]: (state) => {
            state.loading = false;
        },
        [getPartner.fulfilled]: (state, action) => {
            state.loading = false;
            state.listPartner = action.payload.data;
        },
        [getReport.pending]: (state) => {
            state.loading = true;
        },
        [getReport.rejected]: (state) => {
            state.loading = false;
        },
        [getReport.fulfilled]: (state, action) => {
            state.loading = false;
            state.listReport = action.payload.data;
        },
        [getPromotionVideo.pending]: (state) => {
            state.loading = true;
        },
        [getPromotionVideo.rejected]: (state) => {
            state.loading = false;
        },
        [getPromotionVideo.fulfilled]: (state, action) => {
            state.loading = false;
            state.listPromotionVideo = action.payload.data;
        },
        [getSaleChanelByType.pending]: (state) => {
            state.loading = true;
        },
        [getSaleChanelByType.rejected]: (state) => {
            state.loading = false;
        },
        [getSaleChanelByType.fulfilled]: (state, action) => {
            state.loading = false;
            state.listSaleChanel = action.payload.data;
        },
    },
});

const { reducer: aboutUsReducer } = aboutUsSlice;
export default aboutUsReducer;
