import axiosClient from './AxiosClient';
export const applicationApi = {
    getAppById(cateId) {
        const url = `products/search?categoryId=${cateId}`;
        return axiosClient.get(url);
    },
    getDetail(id) {
        const url = `products/${id}`;
        return axiosClient.get(url);
    },
    getCateApplication() {
        const url = `categories/applications`;
        return axiosClient.get(url);
    },
    getListApplication() {
        const url = `/categories/applications`;
        return axiosClient.get(url);
    },
    getListCategoryApplication(cateId) {
        const url = `products/all-paging?categoryId=${cateId}&type=APPLICATION&page=1&size=50`;
        return axiosClient.get(url);
    },
};
